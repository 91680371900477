<template>
  <div id="SupportTickets">
    <!-- header -->
    <div class="d-flex align-items-center mb-1">
      <h4 class="mb-0">Support Tickets</h4>
      <b-link to="/tickets/create" class="btn btn-success ml-auto"> Create Ticket </b-link>
    </div>
    <b-card no-body>
      <b-card-header><h5 class="mb-0">Filter Support Tickets</h5></b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="3" v-if="getRole != 'User'">
            <b-form-group label="User">
              <v-select v-model="refUser" :options="users" clearable @search="searchUser" />
            </b-form-group>
          </b-col>
          <b-col cols="3" v-if="getRole != 'User'">
            <b-form-group label="Last Reply">
              <v-select
                v-model="refLastReplyUser"
                :options="lastReplyUsers"
                clearable
                @search="searchLastReply"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Department">
              <b-form-select v-model="refDepartment" :options="departments" />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Priority">
              <b-form-select v-model="refPriority" :options="ticketPriorities" />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Current Status">
              <b-form-select v-model="refStatus" :options="statuses" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <h5 class="mb-0">Tickets</h5>
      </b-card-header>
      <b-table
        ref="ticketsTableRef"
        :fields="ticketsTableFields"
        show-empty
        empty-text="Sorry! No tickets found for selected criteria."
        :items="fetchTickets"
        :empty-html="
          isLoading
            ? `<div class='text-center'><span class='fa fa-spinner fa-spin'></span> Loading...</div>`
            : `Sorry! No tickets found for selected criteria.`
        "
      >
        <template #cell(user)="row">
          <b-link
            v-if="row.item.userId"
            :href="`/profile/${row.item.userId.username}`"
            target="_blank"
            >{{ row.item.userId.username }}</b-link
          >
        </template>
        <template #cell(ticketNumber)="row">
          <b-link target="_blank" :to="`/tickets/view/${row.item._id}`">{{
            row.item.ticketNumber
          }}</b-link>
        </template>
        <template #cell(subject)="row">
          <b-link target="_blank" :to="`/tickets/view/${row.item._id}`">{{
            row.item.subject
          }}</b-link>
        </template>
        <template #cell(priority)="row">
          <b-badge :variant="ticketPriorities.find((x) => row.item.priority == x.value).color">{{
            ticketPriorities.find((x) => row.item.priority == x.value).text
          }}</b-badge>
        </template>
        <template #cell(department)="row">{{
          row.item.departmentId ? row.item.departmentId.name : "n/a"
        }}</template>
        <template #cell(status)="row">
          <b-badge v-if="row.item.status" :variant="row.item.status.color">{{
            row.item.status.name
          }}</b-badge>
        </template>
        <template #cell(lastReply)="row">
          <b-link
            v-if="row.item.lastReplyUserId"
            :href="`/profile/${row.item.lastReplyUserId.username}`"
            target="_blank"
            >{{ row.item.lastReplyUserId.username }}</b-link
          >
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import {
  BCard,
  BCardHeader,
  BCardBody,
  BTable,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BLink,
  BBadge,
  BPagination,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import useTickets from "../useTickets";

export default {
  name: "SupportTicketsList",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BTable,
    BButton,
    vSelect,
    BCol,
    BRow,
    BFormGroup,
    BFormSelect,
    BLink,
    BBadge,
    BSpinner,
  },
  data() {
    return {
      users: [],
      lastReplyUsers: [],
      departments: [],
      statuses: [],
    };
  },
  mounted() {
    this.getDepartmentsOptions();
    this.getStatusOptions();
  },
  computed: {
    getRole() {
      return this.$store.getters["auth/getRole"];
    },
  },
  methods: {
    async searchUser(query) {
      if (query.length < 3) {
        this.users = [];
        return;
      }
      try {
        const formData = new FormData();
        formData.append("username", query);
        formData.append("perPage", 10);
        formData.append("page", 1);
        const { data } = await axios.post("/support/usernameSearch", formData);
        this.users = data.data.map((x) => ({ value: x._id, label: x.username }));
      } catch (e) {
        this.users = [];
      }
    },
    async searchLastReply(query) {
      if (query.length < 3) {
        this.lastReplyUsers = [];
        return;
      }
      try {
        const formData = new FormData();
        formData.append("username", query);
        formData.append("perPage", 10);
        formData.append("page", 1);
        const { data } = await axios.post("/support/usernameSearch", formData);
        this.lastReplyUsers = data.data.map((x) => ({ value: x._id, label: x.username }));
      } catch (e) {
        this.lastReplyUsers = [];
      }
    },
    async getDepartmentsOptions() {
      const { data } = await axios.get("/support/departments");
      const dep = [
        {
          value: null,
          text: "All Departments",
        },
      ];
      data.data.map((row) => {
        dep.push({
          value: row._id,
          text: row.name,
        });
      });
      this.departments = dep;
    },
    async getStatusOptions() {
      const { data } = await axios.get("/support/status");
      const status = [
        {
          value: null,
          text: "All Statuses",
        },
      ];
      data.data.map((row) => {
        status.push({
          value: row._id,
          text: row.name,
        });
      });
      this.statuses = status;
    },
  },
  setup() {
    const {
      ticketsTableFields,
      ticketsTableRef,
      ticketPriorities,
      refUser,
      refLastReplyUser,
      refStatus,
      refPriority,
      refDepartment,
      fetchTickets,
      isLoading,
    } = useTickets();
    return {
      ticketsTableFields,
      ticketsTableRef,
      ticketPriorities,
      refUser,
      refLastReplyUser,
      refStatus,
      refPriority,
      refDepartment,
      fetchTickets,
      isLoading,
    };
  },
};
</script>
